<template>
  <div class="testimonies template-1">
    <page-header>
      <h1>{{ translations.tcTestimonies }}</h1>
    </page-header>
    <security-banner v-if="!allowPage" :i18n="translations.components" />
    <div v-if="allowPage">
      <!-- / Camp Selector -->
      <page-body :bg="true">
        <section class="body-header">
          <div class="action-bar d-flex justify-content-between">
            <div class="f-container d-flex align-items-center">
              <p>{{ translations.tcTestimonyType }}:</p>
              <div class="filters btn-group d-flex">
                <b-form-select :disabled="moreTypeDisabled" :value="selectedTestimony" @change="handleTypeChange($event)"
                  class="form-control flex-1 g-select">
                  <template slot="first">
                    <option :value="null" disabled>{{ translations.tcSelectAType }}</option>
                    <option value="0">{{ translations.tcAll }}</option>
                  </template>
                  <option v-for="tt in testimonyTypesTranslated" :key="tt.key"
                    :value="'%22' + tt.text.replace('/', '-') + '%22'">{{ tt.text }}</option>
                </b-form-select>
              </div>
            </div>
            <div class="actions d-flex">
              <b-button :to="{ name: 'submit-testimony' }" v-if="okToAddTestimony" variant="tertiary"
                class="flex-0 mr-0 mr-sm-3 d-block w-100-sd mb-3 mb-sm-0">{{ translations.tcSubmitATestimony }}</b-button>
              <b-form-input :title="translations.tcSearch" id="search" class="h-100" :placeholder="translations.tcSearch"
                v-model="searchTextVal" @change="handleSearchTermChange" size="lg" required>
              </b-form-input>
              <b-button variant="primary" size="lg" class="btn btn-primary text-uppercase" @click="handleSearchClick">
                {{ translations.tcSearch }}
              </b-button>
            </div>
          </div>
        </section>
        <section class="ann-cards">
          <div class="row">
            <div v-for="(ann, index) in testimonies" :key="index" class="col-4 mb-4 col-100-sd">
              <AnnCard :obj="ann" @view_more="handleViewMore($event)" :i18n="translations.components">
                <template v-slot:header>
                  <h5>{{ translateTestimonyType(ann.testimony_type, ann.tst_key) }}</h5>
                </template>
              </AnnCard>
            </div>
          </div>
        </section>
        <b-button :disabled="moreTypeDisabled" variant="tertiary" @click="handleMoreTestimoniesClick"
          class="btn-w-sm flex-0 mt-3 mr-0 mr-sm-3 d-block w-100-sd mb-0">{{ translations.tcMoreTestimonies }}</b-button>
      </page-body>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import AnnCard from '@/components/ann-card/AnnCard.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'testimonies',
  mixins: [translationMixin],
  data() {
    return {
      moreTypeDisabled: false,
      searchTextVal: '',
      testimonyTypesTranslated: [],
      translations: {},
    }
  },
  components: {
    AnnCard: AnnCard,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    securityBanner: SecurityBanner,
  },
  methods: {
    ...mapActions({
      getFilteredTestimonies: 'testimony/getFilteredTestimonies',
      getMoreFilteredTestimonies: 'testimony/getMoreFilteredTestimonies',
      getTestimonies: 'testimony/getTestimonies',
      getTestimonyTypes: 'testimony/getTestimonyTypes',
      searchTestimonies: 'testimony/searchArticles',
      setReturnedRows: 'testimony/setReturnedRows',
      setSelectedTestimonyKey: 'testimony/setSelectedTestimonyKey',
      setSearchTerm: 'testimony/setSearchTerm',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedArticleKey: 'user/setSelectedArticleKey',
    }),
    async handleTypeChange(evt) {
      this.setReturnedRows(6)
      await Promise.all([this.applyTypeFilter(evt), this.setSelectedTestimonyKey(evt)])
    },
    async applyTypeFilter(evt) {
      try {
        await Promise.all([
          this.setLoadingStatus(true),
          this.getFilteredTestimonies({ rows: this.returnedRows, search: this.searchTextVal, filter: evt }),
        ])
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async handleViewMore(evt) {
      await this.setSelectedArticleKey(evt.art_key)
      this.$router.push('/get-connected/stay-informed/testimony')
    },
    async handleMoreTestimoniesClick() {
      try {
        this.setReturnedRows(this.returnedRows + 6)
        await Promise.all([this.setLoadingStatus(true), this.applyTypeFilter(this.selectedTestimony)])
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    handleSearchTermChange(q) {
      this.moreTypeDisabled = q.length > 0
    },
    async handleSearchClick() {
      try {
        this.setLoadingStatus(true)
        this.moreTypeDisabled = false
        this.setReturnedRows(6)
        await Promise.all([await this.setSearchTerm(this.searchTextVal), await this.page_load()])
      } catch (e) {
        console.error(e)
      }
    },
    async page_load() {
      try {
        this.setLoadingStatus(true)
        if (this.isFiltering) {
          await this.applyTypeFilter(this.selectedTestimony)
        } else {
          await Promise.all([
            this.getTestimonies({
              rows: this.returnedRows,
              selected_key: this.userSelectedArticleKey,
            }),
          ])
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    translateTestimonyType(givenText, givenGuid) {
      if (!givenGuid && givenText === 'Other') return this.translations.tcOther
      if (!givenText || !givenGuid || !this.translations.common) return givenText

      return this.translations.common['testimony-type'].hasOwnProperty(givenGuid)
        ? this.translations.common['testimony-type'][givenGuid]
        : givenText
    },
  },
  computed: {
    ...mapGetters({
      isInMenu: 'menu/isInMenu',
      prefCulture: 'user/userPreferredCulture',
      returnedRows: 'testimony/returnedRows',
      searchTerm: 'testimony/searchTerm',
      selectedTestimony: 'testimony/testimonyKey',
      testimonies: 'testimony/testimonies',
      testimonyTypes: 'testimony/testimonyTypes',
      userLanguageKey: 'user/userLanguageKey',
      userSelectedArticleKey: 'user/userSelectedArticleKey',
    }),
    allowPage() {
      return this.isInMenu(this.$route.path)
    },
    okToAddTestimony() {
      return true
    },
    isFiltering() {
      const hasType = this.selectedTestimonyKey !== 0 && this.selectedTestimonyKey !== '0'
      return !!hasType || !!this.searchTerm
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      this.searchTextVal = this.searchTerm || '' // preset search to store state
      await this.getTestimonyTypes()
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('ann-card', 'camp-select', 'security-banner'),
        this.getComponentTranslations('common.testimony-type'),
      ]).then((results) => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common },
        }
        this.$set(this.translations, 'components', translatedText)
        this.translateDropdownOptions([{ node: 'testimony-type', store: 'testimonyTypes' }])
      })
      await this.page_load()
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.testimonies {
  .page-body.container {
    max-width: 1160px;
  }

  .page-body {
    margin-top: 34px;

    .body-header {
      .actions {
        @include breakpoint(sm) {
          flex-wrap: wrap;
        }

        .search {
          @include breakpoint(sm) {
            order: 1;
          }
        }

        .btn {
          padding-left: 22px;
          padding-right: 22px;

          @include breakpoint(sm) {
            order: 2;
          }
        }
      }
    }

    .action-bar {
      @include breakpoint(sm) {
        flex-wrap: wrap;
      }
    }

    .f-container {
      @include breakpoint(sm) {
        order: 2;
        align-items: flex-start !important;
      }

      p {
        margin-bottom: 0;
        margin-right: 13px;
        color: $gray-200;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;

        @include breakpoint(sm) {
          margin-right: 3rem;
        }
      }
    }

    .filters {
      @include breakpoint(sm) {
        flex-wrap: wrap;
        order: 1;
      }

      .btn {
        padding: 11px 13px;
        font-weight: 700;

        @include breakpoint(sm) {
          justify-content: center;

          &:nth-of-type(1) {
            width: 33%;
            flex: 0 0 33%;
          }

          &:nth-of-type(2) {
            width: calc(33% + 1px);
            flex: 0 0 calc(33% + 1px);
          }

          &:nth-of-type(3) {
            width: calc(34% + 1px);
            flex: 0 0 calc(34% + 1px);
          }

          &:nth-of-type(4) {
            margin-left: 0;
            margin-top: -1px;
            width: 66%;
          }

          &:nth-of-type(5) {
            width: calc(34% + 1px);
            flex: 0 0 calc(34% + 1px);
            margin-top: -1px;
          }
        }
      }

      .btn-primary {
        color: #fff !important;
      }

      .btn-outline {
        color: $gray-200;
        display: flex;
        align-items: center;

        svg {
          margin-left: 5px;
        }
      }
    }

    .ann-cards {
      .ann-card {
        height: 100%;

        header {
          background-color: #003946;
        }

        footer {
          height: auto;
          background-color: #003946;
          color: #fff;
          font-size: 14px;
          font-weight: 300;
          padding: 1px 0 0;
        }

        main {
          h3.title {
            min-height: 52px;
          }

          div.entry.tags {
            height: 50px;

            div.row {
              height: 70px;
              overflow: hidden;
            }

            div.row div.tags {
              height: 50px;
              margin-top: 15px;
            }
          }

          .entry-content {
            margin-top: 15px;
          }
        }
      }
    }
  }
}
</style>
